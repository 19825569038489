<template>
  <td class="pr-3 pl-3 text-right">
    <div class="inline-flex">
      <button
        @click="editTicket"
        title="Edit"
        :class="
          `p-1 shadow-sm border-2 border-gray-500 rounded-md body__table--button cursor-pointer tooltip text-gray-600 mr-1 z-10`
        "
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-4 w-4"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
          />
        </svg>
      </button>
      <a
        @click="
          deleteTicket(
            id,
            `Delete Ticket`,
            'Do you wish to continue?',
            'Continue',
            'Go back',
            'Ticket deleted successfully'
          )
        "
        href="javascript:void(0)"
        title="Delete"
        :class="
          `p-1 shadow-sm border-2 border-gray-500 rounded-md body__table--button cursor-pointer tooltip text-gray-600 mr-1`
        "
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-4 w-4"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
          /></svg
      ></a>
      <a
        v-if="open"
        @click="closeTicket('Ticket closed successfully.')"
        title="Close ticket"
        href="javascript:void(0)"
        :class="
          `p-1 shadow-sm border-2 border-gray-500 rounded-md body__table--button cursor-pointer tooltip text-gray-600`
        "
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-4 w-4"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z"
          /></svg
      ></a>
      <a
        v-if="!open"
        @click="openTicket('Ticket opened successfully.')"
        href="javascript:void(0)"
        title="Open ticket"
        :class="
          `p-1 shadow-sm border-2 border-gray-500 rounded-md body__table--button cursor-pointer tooltip text-gray-600`
        "
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-4 w-4"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
          /></svg
      ></a>
    </div>
  </td>
  <ask-confirmation-dialog
    ref="askConfirmationDialog"
  ></ask-confirmation-dialog>
  <ConfirmationDialog
    v-if="this.showMessage"
    :messageToShow="this.messageToShow"
    :subMessageToShow="this.subMessageToShow"
  />
</template>

<script>
import axios from "axios";

import ConfirmationDialog from "../ConfirmationDialog.vue";
import AskConfirmationDialog from "../../components/AskConfirmationDialog.vue";

export default {
  props: ["id", "name", "open", "customerId", "contactId"],
  components: {
    ConfirmationDialog,
    AskConfirmationDialog,
  },
  data() {
    return {
      closePutForm: true,
      showMessage: false,
      messageToShow: "",
      confirmationMessage: "",
      subMessageToShow: "",
    };
  },
  methods: {
    editTicket() {
      this.$router.push(`/edit-ticket/${this.id}/${this.customerId}`);
    },
    async closeTicket(msg) {
      const options = {
        method: "PUT",
        url: `${this.$cookie.getCookie("API")}/api/v1/tickets/close/${this.id}`,
      };

      axios
        .request(options)
        .then((response) => {
          // this.sendMessage(msg);
          // this.$emit("successClosed", true);
          this.$emit("activeBanner");
          this.$emit("success", true);
          this.$emit("successMsg", msg);
        })
        .catch((error) => {
          // this.$emit("successClosed", false);
          this.$emit("activeBanner");
          this.$emit("success", false);
          this.$emit("successMsg", "Something wrong happened.");
          this.errorHandling(error);
        });
    },
    async openTicket(msg) {
      const options = {
        method: "PUT",
        url: `${this.$cookie.getCookie("API")}/api/v1/tickets/open/${this.id}`,
      };

      axios
        .request(options)
        .then((response) => {
          // this.sendMessage(msg);
          this.$emit("activeBanner");
          this.$emit("success", true);
          this.$emit("successMsg", msg);
        })
        .catch((error) => {
          this.$emit("activeBanner");
          this.$emit("success", false);
          this.$emit("successMsg", "Something wrong happened.");
          this.errorHandling(error);
        });
    },
    async deleteTicket(
      id,
      title,
      confirmationMessage,
      confirmButton,
      goBack,
      successMsg
    ) {
      const r = await this.$refs.askConfirmationDialog.show({
        title: title,
        message: confirmationMessage,
        okButton: confirmButton,
        cancelButton: goBack,
        action: "delete",
      });
      if (r) {
        axios
          .delete(`${this.$cookie.getCookie("API")}/api/v1/tickets/${id}`)
          .then((res) => {
            if (res) {
              // this.sendMessage(successMsg);
              this.$emit("activeBanner");
              this.$emit("success", true);
              this.$emit("successMsg", successMsg);
            }
          })
          .catch((error) => {
            this.errorHandling(error);
          });
      }
    },

    cancelPutForm() {
      this.closePutForm = !this.closePutForm;
      this.$forceUpdate();
    },
    sendMessage(msg, subMsg) {
      this.showMessage = true;
      this.messageToShow = msg;
      this.subMessageToShow = subMsg;
    },
  },
};
</script>

<style></style>
