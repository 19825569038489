<template>
  <!-- Table -->
  <!-- {{ tablePaginatedData }} -->
  <body class="">
    <div class="">
      <table
        :class="[
          tablePaginatedData.length > 0 ? 'ticket_table_height' : '',
          'w-full flex flex-row flex-no-wrap sm:bg-white overflow-hidden my-0',
        ]"
      >
        <thead class="divide-y divide-gray-200 body__table--header__text">
          <tr
            class="body__table--header flex flex-col flex-wrap sm:table-row sm:rounded-none mb-2 sm:mb-0"
          >
            <th
              class="text-left uppercase text-xs"
              v-for="header in tableHeaders"
              :key="header.name"
            >
              <div
                v-if="
                  header.name === 'ticketTable.status' ||
                    header.name === 'ticketTable.priority'
                "
                :class="[
                  header.name === 'ticketTable.updatedDate' ? 'py-3' : '',
                  'inline-flex sm:mt-0 cursor-pointer truncate',
                ]"
              >
                <div
                  class="inline-flex"
                  v-if="header.name === 'ticketTable.status'"
                >
                  <Menu as="div" class="mt-0 inline-block text-left">
                    <div class="rounded">
                      <MenuButton
                        class="p-1 mt-1 group inline-flex justify-center text-xs font-semibold uppercase"
                      >
                        {{ $t("ticket.status") }}
                        <FilterIcon
                          class="flex-shrink-0 ml-1 mt-0.5 h-4 w-4"
                          aria-hidden="true"
                        />
                      </MenuButton>
                    </div>
                    <transition
                      enter-active-class="transition ease-out duration-100"
                      enter-from-class="transform opacity-0 scale-95"
                      enter-to-class="transform opacity-100 scale-100"
                      leave-active-class="transition ease-in duration-75"
                      leave-from-class="transform opacity-100 scale-100"
                      leave-to-class="transform opacity-0 scale-95"
                    >
                      <MenuItems
                        class="rounded origin-top-left absolute mt-2 w-auto shadow-2xl body__header--menu ring-1 ring-black ring-opacity-5 focus:outline-none z-20"
                      >
                        <div class="py-1">
                          <MenuItem
                            v-for="status in status"
                            :key="status.id"
                            v-slot="{ active }"
                          >
                            <a
                              :class="[
                                status.current
                                  ? 'font-medium text-gray-900'
                                  : 'text-gray-500',
                                active ? 'body__header--menu__item' : '',
                                'rounded mx-0 block px-2 py-1 text-xs cursor-pointer',
                              ]"
                              @click="this.pushSearchStatus(status.name)"
                            >
                              <div class="flex justify-between">
                                {{ status.name }}
                                <svg
                                  v-show="
                                    this.searchStatus.includes(status.name)
                                  "
                                  xmlns="http://www.w3.org/2000/svg"
                                  class="h-5 w-5"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                    clip-rule="evenodd"
                                  />
                                </svg>
                              </div>
                            </a>
                          </MenuItem>
                          <MenuItem class="">
                            <a
                              @click="
                                $emit('clearStatusFilter'),
                                  (searchStatus.length = 0)
                              "
                              class="font-normal text-blue-600 text-xs rounded mx-0 block px-2 py-1 cursor-pointer lowercase flex justify-end"
                            >
                              clear
                            </a>
                          </MenuItem>
                        </div>
                      </MenuItems>
                    </transition>
                  </Menu>
                  <div
                    :class="[
                      header.name === 'ticketTable.updatedDate'
                        ? 'py-3'
                        : 'pr-3 pt-1',
                      'inline-flex lg:mt-1 xl:mt-1 sm:mt-0 cursor-pointer truncate',
                    ]"
                    @click="
                      this.setSorting(header.sort, header.name),
                        (header.isSort = !header.isSort)
                    "
                  >
                    <svg
                      v-if="header.sort"
                      xmlns="http://www.w3.org/2000/svg"
                      :class="[
                        header.name === this.activeSorting ? `underline` : '',
                        'ml-0 h-5 w-5',
                        header.name === this.activeSorting ? `underline` : '',
                        'ml-0 h-5 w-5',
                      ]"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        :d="[
                          this.activeSorting === header.name && header.isSort
                            ? 'M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z'
                            : this.activeSorting === header.name &&
                              !header.isSort
                            ? 'M14.707 10.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 12.586V5a1 1 0 012 0v7.586l2.293-2.293a1 1 0 011.414 0z'
                            : 'M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z',
                        ]"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  class="inline-flex"
                  v-if="header.name === 'ticketTable.priority'"
                >
                  <Menu as="div" class="mt-0 inline-block text-left">
                    <div class="rounded">
                      <MenuButton
                        class="p-1 mt-1 group inline-flex justify-center text-xs font-semibold uppercase"
                      >
                        {{ $t("ticket.priority") }}
                        <FilterIcon
                          class="flex-shrink-0 ml-1 mt-0.5 h-4 w-4"
                          aria-hidden="true"
                        />
                      </MenuButton>
                    </div>
                    <transition
                      enter-active-class="transition ease-out duration-100"
                      enter-from-class="transform opacity-0 scale-95"
                      enter-to-class="transform opacity-100 scale-100"
                      leave-active-class="transition ease-in duration-75"
                      leave-from-class="transform opacity-100 scale-100"
                      leave-to-class="transform opacity-0 scale-95"
                    >
                      <MenuItems
                        class="rounded origin-top-left absolute mt-2 w-auto shadow-2xl body__header--menu ring-1 ring-black ring-opacity-5 focus:outline-none z-20"
                      >
                        <div class="py-1">
                          <MenuItem
                            v-for="priority in priorities"
                            :key="priority.id"
                            v-slot="{ active }"
                          >
                            <a
                              :class="[
                                priority.current
                                  ? 'font-medium text-gray-900'
                                  : 'text-gray-500',
                                active ? 'body__header--menu__item' : '',
                                'rounded mx-0 block px-2 py-1 text-xs cursor-pointer',
                              ]"
                              @click="this.pushSearchPriority(priority.name)"
                            >
                              <div class="flex justify-between capitalize">
                                {{ priority.name }}
                                <svg
                                  v-show="
                                    this.searchPriority.includes(priority.name)
                                  "
                                  xmlns="http://www.w3.org/2000/svg"
                                  class="h-5 w-5"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                    clip-rule="evenodd"
                                  />
                                </svg>
                              </div>
                            </a>
                          </MenuItem>
                          <MenuItem>
                            <a
                              @click="
                                $emit('clearPriorityFilter'),
                                  (searchPriority.length = 0)
                              "
                              class="font-normal text-blue-600 text-xs rounded block px-2 py-1 cursor-pointer lowercase flex justify-end"
                            >
                              clear
                            </a>
                          </MenuItem>
                        </div>
                      </MenuItems>
                    </transition>
                  </Menu>
                  <div
                    :class="[
                      header.name === 'ticketTable.updatedDate'
                        ? 'py-3'
                        : 'pr-3 pt-1',
                      'inline-flex lg:mt-1 xl:mt-1 sm:mt-0 cursor-pointer truncate',
                    ]"
                    @click="
                      this.setSorting(header.sort, header.name),
                        (header.isSort = !header.isSort)
                    "
                  >
                    <svg
                      v-if="header.sort"
                      xmlns="http://www.w3.org/2000/svg"
                      :class="[
                        header.name === this.activeSorting ? `underline` : '',
                        'ml-0 h-5 w-5',
                        header.name === this.activeSorting ? `underline` : '',
                        'ml-0 h-5 w-5',
                      ]"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        :d="[
                          this.activeSorting === header.name && header.isSort
                            ? 'M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z'
                            : this.activeSorting === header.name &&
                              !header.isSort
                            ? 'M14.707 10.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 12.586V5a1 1 0 012 0v7.586l2.293-2.293a1 1 0 011.414 0z'
                            : 'M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z',
                        ]"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div
                v-if="
                  header.name !== 'ticketTable.status' &&
                    header.name !== 'ticketTable.priority'
                "
                :class="[
                  header.name === 'ticketTable.updatedDate' ? 'py-3' : 'p-3',
                  'inline-flex lg:mt-1 xl:mt-1 sm:mt-0 cursor-pointer truncate',
                ]"
                @click="
                  this.setSorting(header.sort, header.name),
                    (header.isSort = !header.isSort)
                "
              >
                <span
                  :class="[
                    header.name === this.activeSorting ? `underline` : '',
                    'mr-1',
                  ]"
                >
                  {{ $t(header.name) }}
                </span>
                <svg
                  v-if="header.sort"
                  xmlns="http://www.w3.org/2000/svg"
                  :class="[
                    header.name === this.activeSorting ? `underline` : '',
                    'ml-0 h-5 w-5',
                    header.name === this.activeSorting ? `underline` : '',
                    'ml-0 h-5 w-5',
                  ]"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    :d="[
                      this.activeSorting === header.name && header.isSort
                        ? 'M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z'
                        : this.activeSorting === header.name && !header.isSort
                        ? 'M14.707 10.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 12.586V5a1 1 0 012 0v7.586l2.293-2.293a1 1 0 011.414 0z'
                        : 'M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z',
                    ]"
                  />
                </svg>
              </div>
            </th>
            <th class="relative flex justify-end mt-3.5 pr-3">
              <button
                class="border-2 border-white rounded-xl"
                @click="showHeaderSelector = !showHeaderSelector"
                v-if="tablePaginatedData.length"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                  />
                </svg>
              </button>
              <div class="mt-0 inline-block" v-if="showHeaderSelector">
                <div
                  class="rounded absolute w-auto shadow-2xl body__header--menu ring-1 ring-black ring-opacity-5 focus:outline-none z-20 header_selector_menu"
                >
                  <fieldset class="space-y-1 p-2">
                    <div
                      class="relative flex items-start"
                      v-for="(header, idx) in tableHeadersSelect"
                      :key="header.name"
                    >
                      <div class="flex items-center h-5">
                        <input
                          @input="removeHeader(header.name, idx)"
                          :checked="header.show"
                          :id="header.name"
                          aria-describedby="comments-description"
                          name="comments"
                          type="checkbox"
                          class="focus:ring-green-500 h-4 w-4 body__table-header-filter--text border-gray-300 rounded"
                        />
                      </div>
                      <div class="ml-3 text-sm">
                        <label
                          :for="header.name"
                          class="font-medium text-gray-700 capitalize"
                          >{{ $t(header.name) }}</label
                        >
                      </div>
                    </div>
                  </fieldset>
                  <div>
                    <button
                      class="text-right font-medium text-gray-700 -mt-1"
                      @click="showAllHeaders"
                    >
                      select all
                    </button>
                  </div>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <TicketTableBody
          :key="tableKey"
          :tablePaginatedData="tablePaginatedData"
          :usersSupport="usersSupport"
          :goToDetail="goToDetail"
          :grabAndGoToTicket="grabAndGoToTicket"
          :grabTicket="grabTicket"
          :freeTicket="freeTicket"
          :assignTicket="assignTicket"
          :checkStatusColor="checkStatusColor"
          :checkPriority="checkPriority"
          :successAssign="successAssign"
          :checkStatus="checkStatus"
          :removedHeaders="removedHeaders"
          v-if="tablePaginatedData.length"
          @activeBanner="$emit('activeBanner')"
          @success="$emit('success', $event)"
          @successMsg="$emit('successMsg', $event)"
        />
      </table>
      <div
        v-if="tablePaginatedData.length < 1"
        class="justify-center text-center my-12"
      >
        <svg
          class="mx-auto h-16 w-16 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            vector-effect="non-scaling-stroke"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z"
          />
        </svg>
        <h3 class="mt-2 text-xl font-medium text-gray-900">
          No Tickets
        </h3>
        <p class="mt-1 text-base text-gray-500">
          You don't have any results for this filter.
        </p>
      </div>
      <BackendPagination
        v-if="this.backendPagination && tablePaginatedData.length"
        :to="this.to"
        :from="this.from"
        :total="this.total"
        @firstPage="$emit('firstPage')"
        @previousPage="$emit('previousPage')"
        @nextPage="$emit('nextPage')"
        @lastPage="$emit('lastPage')"
      />
    </div>
  </body>

  <!-- dialog msg -->
  <ComfirmationDialog
    v-if="this.showMessage"
    :messageToShow="this.messageToShow"
    :subMessageToShow="this.subMessageToShow"
  />
  <!-- <ask-confirmation-dialog
    ref="askConfirmationDialog"
  ></ask-confirmation-dialog> -->
</template>

<script>
import axios from "axios";
import {
  CheckCircleIcon,
  ChevronRightIcon,
  MailIcon,
  SortAscendingIcon,
} from "@heroicons/vue/solid";
import { mapGetters } from "vuex";

import ComfirmationDialog from "../ConfirmationDialog.vue";
// import AskConfirmationDialog from "../components/AskConfirmationDialog.vue";
import BackendPagination from "../BackendPagination.vue";
import UpdateTicket from "../../components/tickets/UpdateTicket.vue";
import HoverTooltip from "../../components/HoverTooltip.vue";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { FilterIcon } from "@heroicons/vue/solid";

import TicketTableBody from "./TicketTableBody.vue";

const account =
  localStorage.getItem("account") === ""
    ? this.$store.state.user.activeAccount
    : localStorage.getItem("account");

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const priorities = [
  { id: 1, name: "basse" },
  { id: 2, name: "normale" },
  { id: 3, name: "haute" },
  { id: 4, name: "critique" },
];

export default {
  name: "TicketTable",
  props: [
    "tablePaginatedData",
    "backendPagination",
    "to",
    "from",
    "total",
    "lastPage",
    "statuses",
    "searchStatus",
    "searchPriority",
  ],
  components: {
    CheckCircleIcon,
    ChevronRightIcon,
    MailIcon,
    SortAscendingIcon,
    UpdateTicket,
    BackendPagination,
    ComfirmationDialog,
    HoverTooltip,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    FilterIcon,
    TicketTableBody,
  },
  data() {
    return {
      priorities,
      activeSorting: "",
      account,
      months,
      status: [],
      users: [],
      usersSupport: [],
      updateTicket: false,
      showMessage: false,
      messageToShow: "",
      askConfirmation: false,
      confirmationMessage: "",
      subMessageToShow: "",
      tableHeaders: [
        { name: "ticketTable.subject", sort: "id", isSort: false, show: true },
        { name: "ticketTable.client", isSort: false, show: true },
        {
          name: "ticketTable.status",
          sort: "status_id",
          isSort: false,
          show: true,
        },
        {
          name: "ticketTable.priority",
          sort: "priority_id",
          isSort: false,
          show: true,
        },
        {
          name: "ticketTable.createdDate",
          sort: "created_at",
          isSort: false,
          show: true,
        },
        {
          name: "ticketTable.updatedDate",
          sort: "updated_at",
          isSort: false,
          show: true,
        },
        {
          name: "res. date",
          sort: "estimatedResolutionDate",
          isSort: false,
          show: true,
        },
        { name: "assign" },
        { name: "capture/free" },
      ],
      tableHeadersSelect: [
        { name: "ticketTable.subject", sort: "id", isSort: false, show: true },
        { name: "ticketTable.client", isSort: false, show: true },
        {
          name: "ticketTable.status",
          sort: "status_id",
          isSort: false,
          show: true,
        },
        {
          name: "ticketTable.priority",
          sort: "priority_id",
          isSort: false,
          show: true,
        },
        {
          name: "ticketTable.createdDate",
          sort: "created_at",
          isSort: false,
          show: true,
        },
        {
          name: "ticketTable.updatedDate",
          sort: "updated_at",
          isSort: false,
          show: true,
        },
        {
          name: "res. date",
          sort: "estimatedResolutionDate",
          isSort: false,
          show: true,
        },
        { name: "assign", show: true },
        { name: "capture/free", show: true },
      ],
      ticketAssignedTo: "",
      priority: [
        { id: 1, name: "Basse" },
        { id: 2, name: "Normale" },
        { id: 3, name: "Haute" },
        { id: 4, name: "Critique" },
      ],
      showIdx: "",
      showPriority: false,
      assignIndex: null,
      successAssign: true,
      searchStatus: [],
      searchPriority: [],
      showHeaderSelector: false,
      removedHeaders: [],
      tableKey: false,
    };
  },
  methods: {
    showAllHeaders() {
      this.tableHeaders = [
        { name: "ticketTable.subject", sort: "id", isSort: false, show: true },
        { name: "ticketTable.client", isSort: false, show: true },
        {
          name: "ticketTable.status",
          sort: "status_id",
          isSort: false,
          show: true,
        },
        {
          name: "ticketTable.priority",
          sort: "priority_id",
          isSort: false,
          show: true,
        },
        {
          name: "ticketTable.createdDate",
          sort: "created_at",
          isSort: false,
          show: true,
        },
        {
          name: "ticketTable.updatedDate",
          sort: "updated_at",
          isSort: false,
          show: true,
        },
        {
          name: "res. date",
          sort: "estimatedResolutionDate",
          isSort: false,
          show: true,
        },
        { name: "assign", show: true },
        { name: "capture/free", show: true },
      ];
      this.tableHeadersSelect = [
        { name: "ticketTable.subject", sort: "id", isSort: false, show: true },
        { name: "ticketTable.client", isSort: false, show: true },
        {
          name: "ticketTable.status",
          sort: "status_id",
          isSort: false,
          show: true,
        },
        {
          name: "ticketTable.priority",
          sort: "priority_id",
          isSort: false,
          show: true,
        },
        {
          name: "ticketTable.createdDate",
          sort: "created_at",
          isSort: false,
          show: true,
        },
        {
          name: "ticketTable.updatedDate",
          sort: "updated_at",
          isSort: false,
          show: true,
        },
        {
          name: "res. date",
          sort: "estimatedResolutionDate",
          isSort: false,
          show: true,
        },
        { name: "assign", show: true },
        { name: "capture/free", show: true },
      ];
      this.removedHeaders.length = 0;
      this.showHeaderSelector = false;
    },
    removeHeader(headerName, idx) {
      let headerIndex = null;
      for (let index = 0; index < this.tableHeadersSelect.length; index++) {
        if (
          this.tableHeadersSelect[index].name.toLowerCase() ===
          headerName.toLowerCase()
        ) {
          headerIndex = index;
        }
      }
      let matchHeader = undefined;
      for (let index = 0; index < this.tableHeaders.length; index++) {
        if (
          this.tableHeaders[index].name.toLowerCase() ===
          headerName.toLowerCase()
        ) {
          matchHeader = index;
        }
      }
      if (matchHeader >= 0) {
        this.removedHeaders.push(this.tableHeaders[matchHeader].name);

        this.tableHeadersSelect[headerIndex].show = false;

        this.tableHeaders.splice(matchHeader, 1);
      } else {
        this.tableHeadersSelect[headerIndex].show = true;

        let removeFromRemovedHeaderIndex = this.removedHeaders.indexOf(
          this.tableHeadersSelect[headerIndex].name
        );

        if (removeFromRemovedHeaderIndex !== -1) {
          this.removedHeaders.splice(removeFromRemovedHeaderIndex, 1);
        }
        this.tableHeaders.splice(
          headerIndex,
          0,
          this.tableHeadersSelect[headerIndex]
        );
      }
      this.tableKey = !this.tableKey;
    },
    checkIfHeader(headerName) {
      for (let index = 0; index < this.tableHeaders.length; index++) {
        if (this.tableHeaders[index].name === headerName.toLowerCase()) {
          return true;
        } else {
          return false;
        }
      }
    },
    async getStatus() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/statuses`
        );
        this.status = res.data;
        console.log(res.data);
      } catch (error) {
        this.errorHandling(error);
      }
    },
    pushSearchStatus(status) {
      if (!this.searchStatus.includes(status)) {
        this.searchStatus.push(status);
      } else {
        let index = this.searchStatus.indexOf(status);
        if (index > -1) {
          this.searchStatus.splice(index, 1);
        }
      }
      this.$emit("pushStatusSearch", status);
    },
    pushSearchPriority(priority) {
      if (!this.searchPriority.includes(priority)) {
        this.searchPriority.push(priority);
      } else {
        let index = this.searchPriority.indexOf(priority);
        if (index > -1) {
          this.searchPriority.splice(index, 1);
        }
      }
      this.$emit("pushPrioritySearch", priority);
    },
    async getUsers() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/users`
        );
        if (res) {
          this.users = res.data;
          this.usersSupport = res.data.filter((user) => {
            return user.supportTeam;
          });
        }
      } catch (error) {
        this.errorHandling(error);
      }
    },
    async assignTicket(event, ticketId, ticketIndex, msg) {
      let userId = event.target.value;
      try {
        const res = await axios.put(
          `${this.$cookie.getCookie(
            "API"
          )}/api/v1/tickets/assign/${ticketId}?assignee_id=${userId}`
        );
        if (res) {
          this.assignIndex = ticketIndex;
          this.successAssign = true;
          this.$emit("activeBanner");
          this.$emit("success", true);
          this.$emit("successMsg", msg);
        }
      } catch (error) {
        this.assignIndex = ticketIndex;
        this.successAssign = false;
        this.$emit("activeBanner");
        this.$emit("success", false);
        this.$emit("successMsg", "Something wrong happened.");
        this.errorHandling(error);
      }
    },
    async grabTicket(ticketId, ticketIndex, msg) {
      let userId = localStorage.getItem("userId");
      try {
        const res = await axios.put(
          `${this.$cookie.getCookie(
            "API"
          )}/api/v1/tickets/assign/${ticketId}?assignee_id=${userId}`
        );
        if (res) {
          this.assignIndex = ticketIndex;
          this.successAssign = true;
          this.$emit("activeBanner");
          this.$emit("success", true);
          this.$emit("successMsg", msg);
        }
      } catch (error) {
        this.assignIndex = ticketIndex;
        this.successAssign = false;
        this.$emit("activeBanner");
        this.$emit("success", false);
        this.$emit("successMsg", "Something wrong happened.");
        this.errorHandling(error);
      }
    },
    async freeTicket(ticketId, ticketIndex, msg) {
      try {
        const res = await axios.put(
          `${this.$cookie.getCookie("API")}/api/v1/tickets/free/${ticketId}`
        );
        if (res) {
          this.assignIndex = ticketIndex;
          this.successAssign = true;
          this.$emit("activeBanner");
          this.$emit("success", true);
          this.$emit("successMsg", msg);
        }
      } catch (error) {
        this.assignIndex = ticketIndex;
        this.successAssign = false;
        this.$emit("activeBanner");
        this.$emit("success", false);
        this.$emit("successMsg", "Something wrong happened.");
        this.errorHandling(error);
      }
    },
    async grabAndGoToTicket(ticketId, ticketStatus, ticketableId) {
      let userId = localStorage.getItem("userId");
      try {
        const res = await axios.put(
          `${this.$cookie.getCookie(
            "API"
          )}/api/v1/tickets/assign/${ticketId}?assignee_id=${userId}`
        );
        if (res) {
          this.goToDetail(ticketId, ticketStatus, ticketableId);
          // this.sendMessage(msg);
        }
      } catch (error) {
        this.errorHandling(error);
      }
    },
    setSorting(sort, header) {
      this.$emit("sorting", sort);
      this.activeSorting = header;
    },

    async getStatus() {
      const res = await axios.get(
        `${this.$cookie.getCookie("API")}/api/v1/statuses`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      this.status = res.data;
    },
    checkStatus(id) {
      if (this.status) {
        for (let index = 0; index < this.status.length; index++) {
          if (this.status[index].id == id) {
            return this.status[index].name.slice(0, 10);
          }
        }
      }
    },
    checkStatusColor(id) {
      if (this.status) {
        for (let index = 0; index < this.status.length; index++) {
          if (this.status[index].id == id) {
            return this.status[index].color;
          }
        }
      }
    },
    checkPriority(id) {
      if (this.priority) {
        for (let index = 0; index < this.priority.length; index++) {
          if (this.priority[index].id == id) {
            return this.priority[index].name;
          }
        }
      }
    },
    togglePriority(idx) {
      // check if the index of the item on the list is different from the one that is active, and if the chart is opened
      // when clicked - if the index is not the one active - it will open the one that was clicked and close the one that was active
      if (this.showIdx !== "" && idx != this.showIdx && this.showPriority) {
        this.showIdx = idx;
        this.showPriority = true;
      } else {
        this.showPriority = !this.showPriority;
        this.showIdx = idx;
      }
    },
    setId(id) {
      this.openForm = !this.openForm;
      this.editId = id;
    },
    goToDetail(id, status, ticketableId) {
      let statusName = this.checkStatus(status);
      this.$router.push(`/tickets/${id}/${ticketableId}`);
    },
    async openOrCloseTicket(
      status,
      id,
      confirmationOpenMessage,
      confirmationCloseMessage,
      openMessage,
      closeMessage,
      successMsg,
      titleOpen,
      titleClose,
      confirmButton,
      goBack
    ) {
      this.updateTicket = !this.updateTicket;

      let confirmationMessage = confirmationCloseMessage;
      let subMessage = closeMessage;
      let title = titleClose;
      let sendAction = 0;

      if (status == 0) {
        sendAction = 1;
        confirmationMessage = confirmationOpenMessage;
        subMessage = openMessage;
        title = titleOpen;
      }
      // let r = confirm(confirmationMessage);
      const r = await this.$refs.askConfirmationDialog.show({
        title: title,
        message: confirmationMessage,
        okButton: confirmButton,
        cancelButton: goBack,
      });
      if (r) {
        axios
          .put(
            `${localStorage.getItem(
              "API"
            )}/api/v1/tickets/${id}?customerAccount=${
              this.account
            }&open=${sendAction}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((res) => {
            if (res) {
              this.sendMessage(successMsg, subMessage);
              // window.alert(successMsg);
            }
          });
      }
    },
    sendMessage(msg, subMsg) {
      this.showMessage = true;
      this.messageToShow = msg;
      this.subMessageToShow = subMsg;
    },
  },
  mounted() {
    this.getStatus();
    this.getUsers();
  },
  computed: {
    ...mapGetters(["active", "language"]),
  },
  watch: {
    tableKey: function(val) {
      this.$emit("getTickets");
    },
  },
};
</script>

<style>
.ticket_table_height {
  min-height: 300px;
}
.ticket_subject_width {
  min-width: 300px;
}
.header_selector_menu {
  right: 0px;
  margin-top: 26px;
  z-index: 100;
}
.index_test {
  z-index: 10;
}

.priority_selection {
  padding-block-start: 20px;
}
.pagination_button {
  padding: 8px;
  margin: 2px;
  border-radius: 3px;
  font-size: 1em;
  cursor: pointer;
}
html,
body {
  height: 100%;
}

@media (min-width: 640px) {
  table {
    display: inline-table !important;
  }

  thead tr:not(:first-child) {
    height: 50px;
    display: none;
  }
}
@media only screen and (max-width: 640px) {
  td {
    height: 50px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  }

  th {
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    height: 50px;
  }
  .ticket_subject_width {
    width: auto;
  }
  .status_width {
    width: auto;
  }
}
</style>
