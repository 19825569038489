<template>
  <tbody
    class="flex-1 sm:flex-none divide-y divide-gray-200 body__table--body ticket_table_height"
  >
    <tr
      v-for="(ticket, ticketIdx) in this.tablePaginatedData"
      :key="ticket.id"
      :class="[
        'text-sm flex flex-col flex-wrap sm:table-row mb-2 sm:mb-0 hover:bg-gray-100 cursor-pointer z-0',
      ]"
    >
      <td
        v-if="!removedHeaders.includes('ticketTable.subject')"
        class="p-3 ticket_subject_width"
        @click="goToDetail(ticket.id, ticket.status_id, ticket.ticketable_id)"
      >
        <div class="inline-flex">
          <span
            class="body__table--body__text underline hover:text-malachite "
            >{{ ticket.subject }}</span
          >
        </div>

        <div>
          <span
            v-if="ticket.tags"
            class="inline-flex items-center px-2.5 py-0.5 mt-1 rounded-full text-xs font-medium bg-gray-100 text-gray-800"
          >
            <font-awesome-icon
              icon="tags"
              class="icon alt mr-1 text-gray-600"
            />

            {{ ticket.tags }}</span
          >
        </div>
      </td>
      <td v-if="!removedHeaders.includes('ticketTable.client')" class="p-3">
        <div class="inline-flex">
          <router-link
            :to="`/customer-detail/${ticket.ticketable.id}`"
            class="body__table--body__text underline hover:text-malachite"
            >{{ ticket.ticketable.name }}</router-link
          >
        </div>
      </td>
      <td
        v-if="!removedHeaders.includes('ticketTable.status')"
        class="py-3 truncate"
        @click="goToDetail(ticket.id, ticket.status_id, ticket.ticketable_id)"
      >
        <div class="mt-0 flex items-center text-white">
          <span
            :class="[
              `${this.checkStatusColor(
                ticket.status_id
              )} px-2 inline-flex text-xs leading-4 capitalize font-semibold rounded-full`,
            ]"
          >
            {{ checkStatus(ticket.status_id) }}
          </span>
        </div>
      </td>

      <td
        v-if="!removedHeaders.includes('ticketTable.priority')"
        class="py-3 truncate"
        @click="goToDetail(ticket.id, ticket.status_id, ticket.ticketable_id)"
      >
        <div class="mt-0 flex items-center text-white">
          <p
            :class="[
              ticket.priority_id == 1
                ? `inline-flex items-center px-2 py-0.5 sm:ml-5 rounded-full text-xs font-medium bg-blue-100 text-blue-800`
                : ticket.priority_id == 2
                ? `inline-flex items-center px-2 py-0.5 sm:ml-5 rounded-full text-xs font-medium bg-green-100 text-green-800`
                : ticket.priority_id == 3
                ? `inline-flex items-center px-2 py-0.5 sm:ml-5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800`
                : `inline-flex items-center px-2 py-0.5 sm:ml-5 rounded-full text-xs font-medium bg-red-100 text-red-800`,
            ]"
          >
            {{ checkPriority(ticket.priority_id) }}
          </p>
        </div>
      </td>

      <td
        v-if="!removedHeaders.includes('ticketTable.createdDate')"
        class="p-3 truncate"
        @click="goToDetail(ticket.id, ticket.status_id)"
      >
        <div v-if="this.language === 'en'" class="text-sm">
          {{ formatDate(ticket.created_at) }}
        </div>
        <div v-if="this.language === 'fr'" class="text-sm">
          {{ formatDateFrench(ticket.created_at) }}
        </div>
        <div class="text-sm text-gray-500">
          {{ formatHour(ticket.created_at) }}
        </div>
      </td>

      <td
        v-if="!removedHeaders.includes('ticketTable.updatedDate')"
        class="p-3 truncate"
        @click="goToDetail(ticket.id, ticket.status_id, ticket.ticketable_id)"
      >
        <div v-if="this.language === 'en'" class="text-sm">
          {{ formatDate(ticket.updated_at) }}
        </div>
        <div v-if="this.language === 'fr'" class="text-sm">
          {{ formatDateFrench(ticket.updated_at) }}
        </div>
        <div class="text-sm text-gray-500">
          {{ formatHour(ticket.updated_at) }}
        </div>
      </td>
      <td
        v-if="!removedHeaders.includes('res. date')"
        class="p-3 truncate"
        @click="goToDetail(ticket.id, ticket.status_id, ticket.ticketable_id)"
      >
        <div v-if="this.language === 'en'" class="text-sm">
          {{ formatDate(ticket.estimatedResolutionDate) }}
        </div>
        <div v-if="this.language === 'fr'" class="text-sm">
          {{ formatDateFrench(ticket.estimatedResolutionDate) }}
        </div>
        <div class="text-sm text-gray-500">
          {{ formatHour(ticket.estimatedResolutionDate) }}
        </div>
      </td>

      <td v-if="!removedHeaders.includes('assign')" class="p-3 truncate">
        <span>
          <select
            @change="
              assignTicket(
                $event,
                ticket.id,
                ticketIdx,
                'Ticket assigned successfully.'
              )
            "
            id="country"
            name="country"
            autocomplete="country"
            :class="[
              assignIndex == ticketIdx && successAssign
                ? 'ring-1 ring-green-400 bg-green-100'
                : assignIndex == ticketIdx && !successAssign
                ? 'ring-1 ring-red-400 bg-red-100'
                : '',
              'mt-0 block w-auto py-2 px-5 border border-gray-300 bg-white shadow-sm text-sm truncate',
            ]"
          >
            <option
              v-show="!ticket.assignee_id"
              :selected="!ticket.assignee_id"
              value=""
              class="truncate text-sm"
            ></option>
            <option
              class="truncate text-sm"
              v-for="user in usersSupport"
              :key="user.id"
              :value="user.id"
              :selected="user.id == ticket.assignee_id"
              >{{ user.name }}</option
            >
          </select>
        </span>
      </td>
      <td
        v-if="!removedHeaders.includes('capture/free')"
        class="p-3 text-center"
      >
        <div class="inline-flex">
          <div class="inline-block">
            <a
              v-show="ticket.assignee_id"
              @click="
                freeTicket(
                  ticket.id,
                  ticketIdx,
                  'Ticket unassigned successfully.'
                )
              "
              :title="$t('free')"
              href="javascript:void(0)"
              :class="
                `p-1 shadow-sm border-2 border-gray-500 rounded-md body__table--button cursor-pointer tooltip text-gray-600 inline-flex z-20`
              "
            >
              <HoverTooltip text="Free"/>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M13 7a4 4 0 11-8 0 4 4 0 018 0zM9 14a6 6 0 00-6 6v1h12v-1a6 6 0 00-6-6zM21 12h-6"
                /></svg
            ></a>
          </div>

          <a
            v-show="!ticket.assignee_id"
            @click="
              grabTicket(ticket.id, ticketIdx, 'Ticket assigned successfully.')
            "
            href="javascript:void(0)"
            title="Grab the ticket"
            :class="
              `p-1 shadow-sm border-2 border-gray-500 rounded-md body__table--button cursor-pointer tooltip text-gray-600`
            "
          >
            <HoverTooltip text="Grab"/>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M7 11.5V14m0-2.5v-6a1.5 1.5 0 113 0m-3 6a1.5 1.5 0 00-3 0v2a7.5 7.5 0 0015 0v-5a1.5 1.5 0 00-3 0m-6-3V11m0-5.5v-1a1.5 1.5 0 013 0v1m0 0V11m0-5.5a1.5 1.5 0 013 0v3m0 0V11"
              /></svg
          ></a>
          <a
            v-show="!ticket.assignee_id"
            @click="grabAndGoToTicket(ticket.id, ticket.status_id, ticket.ticketable_id)"
            href="javascript:void(0)"
            title="Grab and go to ticket detail"
            :class="
              `p-1 shadow-sm border-2 border-gray-500 rounded-md body__table--button cursor-pointer tooltip text-gray-600 inline-flex ml-1`
            "
          >
            <HoverTooltip text="Grab/Go to"/>
            <font-awesome-icon icon="sign-in-alt" class="icon alt"
          /></a>
        </div>
      </td>
      <UpdateTicket
        :id="ticket.id"
        :open="ticket.open"
        :customerId="ticket.ticketable_id"
        @activeBanner="$emit('activeBanner')"
        @success="$emit('success', $event)"
        @successMsg="$emit('successMsg', $event)"
      />
    </tr>
  </tbody>
</template>

<script>
import UpdateTicket from "./UpdateTicket.vue";
import { mapGetters } from "vuex";

export default {
  props: [
    "tablePaginatedData",
    "usersSupport",
    "goToDetail",
    "grabAndGoToTicket",
    "grabTicket",
    "freeTicket",
    "assignTicket",
    "checkStatusColor",
    "checkPriority",
    "successAssign",
    "checkStatus",
    "removedHeaders",
  ],
  components: {
    UpdateTicket,
  },
  data() {
    return {
      customersName: [],
    };
  },
  methods: {
    formatDate(date) {
      const dateCreated = new Date(date);
      const formatedDate =
        ("0" + dateCreated.getDate()).slice(-2) +
        "/" +
        ("0" + (dateCreated.getMonth() + 1)).slice(-2) +
        "/" +
        dateCreated.getFullYear();

      return formatedDate;
    },
    formatDateFrench(date) {
      const dateCreated = new Date(date);
      const formatedDate =
        ("0" + dateCreated.getDate()).slice(-2) +
        "/" +
        ("0" + (dateCreated.getMonth() + 1)).slice(-2) +
        "/" +
        dateCreated.getFullYear();

      return formatedDate;
    },
    formatHour(date) {
      const dateCreated = new Date(date);
      const formatedHour =
        ("0" + dateCreated.getHours()).slice(-2) +
        ":" +
        ("0" + dateCreated.getMinutes()).slice(-2);

      return formatedHour;
    },
  },
  computed: {
    ...mapGetters(["language"]),
  },
};
</script>

<style>
.ticket_subject_width {
  min-width: 300px;
}
</style>
